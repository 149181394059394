import React, { RefObject, useEffect, useState } from 'react';
import { enterKeyCode } from './search-input.constants';
import * as Styled from './search-input.styles';
import { GtmEvents } from '../../../helpers/web-analytics.helpers';
import { Textbox } from '../../form-controls/textbox/textbox';
import { SearchSvg } from '../../svg-components/form-controls/search';
import { LoaderCircle } from '../loader-circle/loader-circle';

interface SearchInputProps {
    value?: string;
    placeholder?: string;
    onSearch?: (value: string) => void;
    onChange?: (value: string) => void;
    onFocus?: (value?: string) => void;
    autoFocus?: boolean;
    eventContent?: string;
    className?: string;
    id?: string;
    reference?: RefObject<HTMLInputElement>;
    maxLength?: number;
    itemProp?: string;
    isLoading?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
    onSearch,
    value,
    placeholder = 'Поиск',
    onChange,
    onFocus,
    autoFocus,
    eventContent,
    className,
    id,
    reference,
    maxLength,
    itemProp,
    isLoading,
}) => {
    const [searchValue, setSearchValue] = useState<string | undefined>(value ? value : '');

    useEffect(() => {
        setSearchValue(value);
    }, [value]);

    const handleEnterClick = () => {
        if (searchValue && onSearch) {
            onSearch(searchValue);
            GtmEvents.startSearchRequestHome(eventContent);
        }
    };

    const handleChangeValue = (value: string) => {
        setSearchValue(value);
        onChange ? onChange(value) : null;
    };

    const handleEnterPress = (value: number) => {
        value === enterKeyCode && handleEnterClick();
    };

    const handleFocusEvent = () => {
        onFocus && onFocus();
        GtmEvents.searchFieldFocus(eventContent);
    };

    const handleClearValue = () => {
        handleChangeValue('');
        reference?.current?.focus();
    };

    return (
        <Styled.SearchContainer className={className} itemProp={itemProp} isLoading={isLoading}>
            {isLoading && <LoaderCircle centered />}
            <Textbox
                reference={reference}
                value={searchValue}
                onChange={handleChangeValue}
                onClearValue={handleClearValue}
                onKeyPress={handleEnterPress}
                placeholder={placeholder}
                onFocus={handleFocusEvent}
                autoFocus={autoFocus}
                id={id}
                maxLength={maxLength}
                beforeIcon={<SearchSvg />}
                disabled={isLoading}
                inputCss={Styled.CustomInputStyles}
            />
        </Styled.SearchContainer>
    );
};
