import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { GtmService } from '../web-analytics.helpers';

export class geoAnalytics extends GtmService {
    static geoEvent(eventAction: WebAnalyticsEventActionEnum, eventLabel: WebAnalyticsEventLabelsEnum, actionGroup: WebAnalyticsActionGroupEnum, city : string | null) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vtnGeo,
            eventCategory: WebAnalyticsEventCategoryEnum.geo,
            eventAction: eventAction,
            eventLabel: eventLabel,
            eventContent: city,
            actionGroup: actionGroup,
        });

        this.log(this.geoEvent.name);
    }
}
