import React, { useContext, useEffect } from 'react';
import * as Styled from './header-top-controls.styles';
import { FirstVisitContext } from '../../../context/first-visit-context';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { geoAnalytics } from '../../../helpers/web-analytics/geo-analytics';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventActionEnum, WebAnalyticsEventLabelsEnum } from '../../../models/enums/web-analytics.enum';
import { GeoCity } from '../geo-city/geo-city';
import { SegmentsMenu } from '../segments-menu/segments-menu';

export const HeaderTopControls: React.FC<{
    cityName: string | null;
    onCityClick: () => void;
}> = ({ cityName, onCityClick }) => {
    const { isApp } = useIsApp();
    const isFirstVisit = useContext(FirstVisitContext);

    useEffect(() => {
        if (isFirstVisit) {
            geoAnalytics.geoEvent(WebAnalyticsEventActionEnum.confirmed, WebAnalyticsEventLabelsEnum.autoGeo, WebAnalyticsActionGroupEnum.nonInteractions, cityName);
        }
    }, []);

    const changeCity = () => {
        geoAnalytics.geoEvent(WebAnalyticsEventActionEnum.elementClick, WebAnalyticsEventLabelsEnum.changeGeo, WebAnalyticsActionGroupEnum.interactions, cityName);
        onCityClick();
    };

    return (
        <Styled.HeaderTopControls>
            {!isApp && <SegmentsMenu />}
            <GeoCity cityName={cityName} onCityClick={changeCity} />
        </Styled.HeaderTopControls>
    );
};
